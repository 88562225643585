import { format } from 'date-fns';
import cn from 'classnames';
import { FooterProps } from './Footer.props';
import styles from './Footer.module.css';

const Footer = ({ className, ...props }: FooterProps) => {
  return (
    <footer className={cn('bg-gray-800', styles.footer, className)} {...props}>
      <span>
        Copyright © 2024 - {format(new Date(), 'yyyy')}. All Right Reserved.
      </span>
    </footer>
  );
};

export default Footer;
