import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import cn from 'classnames';
import NewsItem from '@app/components/NewsItem/NewsItem';
import { SidebarProps } from './Sidebar.props';
import styles from './Sidebar.module.css';

const Sidebar = ({ className, ...props }: SidebarProps) => {
  return (
    <aside className={cn(styles.sidebar, className)} {...props}>
      <h2 className={cn(styles.sidebar_title)}>Solana Nft Collections</h2>
      <div className={cn(styles.news)}>
        <Link href="https://magiceden.io/marketplace/dmnc">
          <Image
            src="/images/dark_moon.webp"
            alt={`Dark Moon Nft Collection`}
            width={80}
            height={80}
            loading="lazy"
            className="my-2 mr-2"
          />
        </Link>
        <Link href="https://magiceden.io/marketplace/dmnc" className="link">
          <span className={styles.link_title}>Dark Moon</span>
        </Link>
      </div>
      <div className={cn(styles.news)}>
        <Link href="https://magiceden.io/marketplace/fanc">
          <Image
            src="/images/fantasy.webp"
            alt={`Fantasy AI Nft Collection`}
            width={80}
            height={80}
            loading="lazy"
            className="my-2 mr-2"
          />
        </Link>
        <Link href="https://magiceden.io/marketplace/fanc" className="link">
          <span className={styles.link_title}>Fantasy AI Nft Collection</span>
        </Link>
      </div>

      <h2 className={cn(styles.sidebar_title)}>Links</h2>
      <NewsItem
        title="NFT Market"
        content="The NFT marketplace. Nft news and reviews."
        url="https://marchcat.com/"
      />
      <NewsItem
        title="News cryptocurrency DeFi"
        content="News cryptocurrency DeFi."
        url="https://newscryptodefi.com/"
      />
    </aside>
  );
};

export default Sidebar;
