import(/* webpackMode: "eager" */ "/var/www/marchcat/data/www/newscryptodefi.online/client/app/globals.css");
;
import(/* webpackMode: "eager" */ "/var/www/marchcat/data/www/newscryptodefi.online/client/assets/css/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/marchcat/data/www/newscryptodefi.online/client/components/TopNav/TopNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/var/www/marchcat/data/www/newscryptodefi.online/client/context/auth.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WalletProvider"] */ "/var/www/marchcat/data/www/newscryptodefi.online/client/context/connectWallet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/marchcat/data/www/newscryptodefi.online/client/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/var/www/marchcat/data/www/newscryptodefi.online/client/node_modules/@ant-design/v5-patch-for-react-19/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/var/www/marchcat/data/www/newscryptodefi.online/client/node_modules/react-hot-toast/dist/index.mjs");
