import Link from 'next/link';
import cn from 'classnames';
import Image from 'next/image';
import styles from './News.module.css';

const News = () => {
  return (
    <div className="md:mx-10 2xl:mx-40 mt-4">
      <h1 className="text-3xl font-bold">Cryptocurrency News</h1>
      {/*  */}
      <div className="flex flex-col">
        <p className="mt-4 text-xs italic">2025-01-22</p>
        <p className="mt-1 text-base font-normal">
          <Image
            src="/images/news/2024-07-22_05-39-47_5971.webp"
            alt={`Why Crypto Insiders Are Bullish on JetBolt, Solana, and XRP`}
            width={300}
            height={300}
            priority={true}
            className="xl:mr-3 my-3 xl:float-right"
          />
          The cryptocurrency market is known for its volatility and
          unpredictability, but one thing is certain - crypto insiders are
          always on the lookout for the next big thing. Recently, three
          cryptocurrencies have caught the attention of insiders and enthusiasts
          alike: JetBolt, Solana, and XRP. In this review, we will delve into
          the reasons behind the bullish sentiment surrounding these tokens and
          explore their potential for future growth.
          <br />
          <Link
            href="/news/why-crypto-insiders-are-bullish-on-jetbolt-solana-and-xrp/"
            className="link"
          >
            Why Crypto Insiders Are Bullish on JetBolt, Solana, and XRP
          </Link>
        </p>
      </div>
      {/*  */}
      <div className="flex flex-col">
        <p className="mt-4 text-xs italic">2025-01-01</p>
        <p className="mt-1 text-base font-normal">
          <Image
            src="/images/news/2024-07-22_04-09-17_4457.webp"
            alt={`Grayscale's Q1 Favorites: Can Solana Reach $1,000?`}
            width={300}
            height={300}
            priority={true}
            className="xl:mr-3 my-3 xl:float-left"
          />
          The cryptocurrency market has been abuzz with the recent announcement
          from Grayscale, a leading digital asset management company, which has
          added six new tokens to its Q1 2025 top 20 list. The list highlights
          the growing trends of DeFi, decentralized AI, and Solana, with a
          particular focus on Solana DeFi apps. This development has sparked
          speculation about the potential of Solana (SOL) to reach $1,000. In
          this review, we will delve into the possibilities and explore the
          factors that could influence the price of SOL.
          <br />
          <Link
            href="/news/grayscales-q1-favorites-can-solana-reach-1000/"
            className="link"
          >
            Grayscale&apos;s Q1 Favorites: Can Solana Reach $1,000?
          </Link>
        </p>
      </div>
      {/*  */}
      <div className="flex flex-col">
        <p className="mt-4 text-xs italic">2024-12-28</p>
        <p className="mt-1 text-base font-normal">
          <Image
            src="/images/news/2024-07-22_02-38-54_5083.webp"
            alt={`Catzilla, the Solana-based Meme Coin Poised to Disrupt the Crypto Market`}
            width={300}
            height={300}
            loading="lazy"
            className="xl:ml-3 my-3 xl:float-right"
          />
          The cryptocurrency market is abuzz with the emergence of a new meme
          coin, Catzilla, built on the Solana network. This digital asset has
          been making waves with its promise of delivering astronomical returns,
          potentially surpassing 12,000% in the coming year. According to a
          prominent Bitcoin investor, Catzilla is poised to bypass Dogecoin and
          Shiba Inu, two of the most popular meme coins in the market, by 2025.
          <br />
          <Link
            href="/news/catzilla-the-solana-based-meme-coin-poised-to-disrupt-the-crypto-market/"
            className="link"
          >
            Catzilla, the Solana-based Meme Coin Poised to Disrupt the Crypto
            Market
          </Link>
        </p>
      </div>
      {/*  */}
      <div className="flex flex-col">
        <p className="mt-4 text-xs italic">2024-12-19</p>
        <p className="mt-1 text-base font-normal">
          <Image
            src="/images/news/2024-07-22_01-04-23_7079.webp"
            alt={`Solana Smashes Record With 66.9M Daily Transactions as Pengu Token Debuts`}
            width={300}
            height={300}
            loading="lazy"
            className="xl:mr-3 my-3 xl:float-left"
          />
          In a remarkable display of network activity, Solana has shattered its
          previous records with an astonishing 66.9 million daily transactions,
          surpassing the combined activity of other leading blockchains. This
          surge in activity was triggered by the debut of the Pengu token, a
          native token of the Pudgy Penguins NFT project, on the Solana
          blockchain.
          <br />
          <Link
            href="/news/solana-smashes-record-with-67m-daily-transactions-as-pengu-token-debuts/"
            className="link"
          >
            Solana Smashes Record With 66.9M Daily Transactions as Pengu Token
            Debuts
          </Link>
        </p>
      </div>
      {/*  */}
      <div className="flex flex-col">
        <p className="mt-4 text-xs italic">2024-07-25</p>
        <p className="mt-1 text-base font-normal">
          <Image
            src="/images/news/2024-04-08_22-46-10_6095.webp"
            alt={`A Surge in Popularity: MEW, WIF, and Solana Memecoins Amid Network Activity`}
            width={300}
            height={300}
            loading="lazy"
            className="xl:ml-3 my-3 xl:float-right"
          />
          In the dynamic world of cryptocurrency, three notable entities have
          recently experienced a significant surge in popularity: MyEtherWallet
          (MEW), Wallet Import Format (WIF), and Solana memecoins. This rise can
          be attributed to the increasing network activity within the
          cryptocurrency ecosystem.
          <br />
          <Link
            href="/news/a-surge-in-popularity-mew-wif-and-solana-memecoins-amid-network-activity/"
            className="link"
          >
            A Surge in Popularity: MEW, WIF, and Solana Memecoins Amid Network
            Activity
          </Link>
        </p>
      </div>
      {/*  */}
      <div className="flex flex-col">
        <p className="mt-4 text-xs italic">2024-05-29</p>
        <p className="mt-1 text-base font-normal">
          <Image
            src="/images/news/2024-04-08_21-12-11_6873.webp"
            alt={`Crypto Protocols unleash AI Power to push boundaries of DeFi`}
            width={300}
            height={300}
            loading="lazy"
            className="xl:mr-3 my-3 xl:float-left"
          />
          Crypto protocols are increasingly leveraging the power of artificial
          intelligence (AI) to push the boundaries of decentralized finance
          (DeFi) and unlock new use cases. The integration of AI and DeFi
          presents exciting possibilities.
          <br />
          <Link
            href="/news/crypto-protocols-unleash-ai-power-to-push-boundaries-of-defi/"
            className="link"
          >
            Crypto Protocols unleash AI Power to push boundaries of DeFi
          </Link>
        </p>
      </div>
      {/*  */}
      <div className="flex flex-col">
        <p className="mt-4 text-xs italic">2024-05-29</p>
        <p className="mt-1 text-base font-normal">
          <Image
            src="/images/news/2024-04-08_19-35-09_4835.webp"
            alt={`DeFi markets surge in 2024 as capital inflows drive growth`}
            width={300}
            height={300}
            loading="lazy"
            className="xl:ml-3 my-3 xl:float-right"
          />
          According to the search results, DeFi markets have seen a significant
          surge in capital inflows during 2024.
          <br />
          <Link
            href="/news/defi-markets-surge-in-2024-as-capital-inflows-drive-growth/"
            className="link"
          >
            DeFi markets surge in 2024 as capital inflows drive growth
          </Link>
        </p>
      </div>
    </div>
  );
};

export default News;
