import cn from 'classnames';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Footer from '@app/components/Footer/Footer';
import { BaseLayoutProps } from './BaseLayout.props';
import styles from './BaseLayout.module.css';

import type { JSX } from "react";

const BaseLayout = ({
  children,
  className,
  ...props
}: BaseLayoutProps): JSX.Element => {
  return (
    <div className={cn(styles.baseLayout, className)} {...props}>
      <Sidebar className={styles.sidebar} />
      <main className={styles.body}>{children}</main>
      <Footer className={styles.footer} />
    </div>
  );
};

export default BaseLayout;
